import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/auth/:authtoken',
    name: 'Auth',
    component: () => import('../views/Auth.vue'),
    props: true
  },
  {
    path: '/profile/:uuid',
    name: 'User',
    component: () => import('../views/Profile.vue'),
    props: true
  },
  {
    path: '/submission/:uuid',
    name: 'Submission',
    component: () => import('../views/Submission.vue'),
    props: true
  },
  {
    path: '/orgsubmission/:uuid',
    name: 'OrgSubmission',
    component: () => import('../views/OrgSubmission.vue'),
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
